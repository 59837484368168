import { GetServerSideProps } from 'next'

import { List } from '../../components/List/List'
import { ListItemTicket } from '../../components/List/ListItemTicket/ListItemTicket'
import { ListItemCalendarTicket } from '../../components/List/ListItemCalendarTicket/ListItemCalendarTicket'
import { BestPriceGuarantee } from '../../components/Banners/BestPriceGuarantee/BestPriceGuarantee'
import { FloatingCart } from '../../components/Cart/FloatingCart/FloatingCart'
import { ContinueLink } from '../../components/Misc/ContinueLink/ContinueLink'
import { TextApiResponseType, TextType } from '../../types/TextType'
import { PageView } from '../../components/Analytics/PageView/PageView'

import type { ProductType } from '../../types/ProductType'
import { useContext, useEffect } from 'react';
import { CartContext } from '../../contexts/CartContext';
import { YearContext } from '../../contexts/YearContext';
import Head from 'next/head';
import { YearPicker } from '../../components/List/YearPicker/YearPicker'

// last 2 ids are epic tickets
const ticketIds = [234481, 328366, 234771, 333439, 336334] // 2 Park and 3 Park tickets
const textTypes = ['ticket_page_intro']

function ParkTickets({ products, texts }: { products: Array<ProductType>, texts: Array<TextType> }) {
  const {setYear} = useContext(YearContext)
  const { year } = useContext(YearContext)

  const title = "Tickets"
  const introText = texts.find(t => t.text_type === 'ticket_page_intro')?.body ?? ''

  const {cart} = useContext(CartContext)
  useEffect(()=>{
    cart.data.tickets.map(ticket => {
      if(ticket.tags.includes('rooms')){
        setYear && setYear(ticket.date.split('-').slice(0,1).join())
      }
    })
  },[cart,setYear])

  let availableYears: (string | string[] | undefined)[] = products.map((product, index) => {
    if (product.seasons.length > 0) {
      if (Array.from(new Set(product.seasons.filter(s => s.title.match(/^20[0-9]{2}$/)).map(s => s.title))).length > 1) {
        return Array.from(new Set(product.seasons.filter(s => s.title.match(/^20[0-9]{2}$/)).map(s => s.title)))
      } else {
        return [product.calendar_first_available_month.substring(0,4), product.calendar_last_available_month.substring(0,4)]
      }
    } else {
      // check if product has values in last and first available months
      if (product.calendar_first_available_month.substring(0, 4) !== '' && product.calendar_last_available_month.substring(0, 4) !== '') {
        return [product.calendar_first_available_month.substring(0, 4), product.calendar_last_available_month.substring(0, 4)]
      }
    }
  })

  availableYears = availableYears.flat()
  availableYears = availableYears.filter((item, index) => {
    return (availableYears.indexOf(item) === index && item !== undefined);
  })
  const yearPicker = <YearPicker name={ 'year-' + products[0].id } years={availableYears} />

  return (
    <>
      <Head key={'park-tickets'}><title>Universal Orlando™ | Park Tickets</title></Head>
      <PageView
        pageName='Park Tickets'
        productsOnPage={ products }
      />
      <main className='products'>
        <FloatingCart showNextStep={true} currentSection='tickets' placement="top" />
        <ContinueLink currentSection='tickets' />
        <List title={title} introText={introText} rating={''}>
        {yearPicker}
          {
            // The logic below is to hide the 3 park dated ticket (NID 234481) when the user has selected 2024 and to hide the 3 park non-dated ticket when they choose 2025
            products.map((product: ProductType) => {
              if (product.calendar_based_pricing === 1) {
                if (year === '2025') {return (
                <ListItemCalendarTicket
                  key={product.id}
                  product={product}
                  isDatedTicket={true}
                />
              )}
              return
              } else if (year === '2024'){
                return (
                <ListItemTicket
                  key={product.id}
                  product={product}
                />
              )
              }

            })
          }
        </List>
        <FloatingCart showNextStep={true} currentSection='tickets' placement="bottom" />
      </main>
      <BestPriceGuarantee />
    </>
  )
}

export const getServerSideProps: GetServerSideProps = async () => {

  // Get products:
  const fetches = ticketIds.map(ticketId => fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/products/${ticketId}?view=website`))
  const results = await Promise.all(fetches)

  const products: Array<ProductType | null> = await Promise.all(results.map(result => {

    if (result.statusText === 'OK') {
      return result.json()
    } else {
      return null
    }
  }))

  // Get page text:
  const textResult = await fetch(`${process.env.NEXT_PUBLIC_API_BASE_URL}/text?types=${textTypes.join(",")}`)
  const texts: TextApiResponseType = await textResult.json()

  return { props: {
    products: products.filter(p => p !== null),
    texts: texts.data,
  }}
}

export default ParkTickets

